import React, { Fragment, useState } from 'react';

import { DownloadCsv } from 'components/controls/DownloadCsv';

const stageFromDeployEnv = process.env.REACT_APP_DEPLOY_STAGE;

export default function DownloadFiles() {
	const [loading, setLoading] = useState(false);

	return(<Fragment>
		<p>Click below to download a PDF pricelist for printing:</p>
		<button
			type="button"
			name="download-pricelist"
			className="download-pricelist lightblue-rounded-button"
			disabled={loading}
			onClick={() => {
				pdfPlainDownload();
			}}
		>
			<span>Download PDF</span>
		</button>

		{stageFromDeployEnv !== "prod" ?
		<button
			type="button"
			name="download-pricelist"
			className="download-pricelist lightblue-rounded-button"
			disabled={loading}
			onClick={() => {
				pdfGenerateDynamic(setLoading);
			}}
		>
			{loading ? <span><i>Generating PDF...</i></span> : <span>Generate</span>}
			</button>
			: <Fragment />
		}

		<DownloadCsv />

	</Fragment>);
}

function pdfPlainDownload() {
	var a = document.createElement('a');
	a.href = process.env.REACT_APP_PRICELIST_PDF;
	a.target="_blank";
	a.setAttribute('rel', 'noopener noreferrer');
	document.body.appendChild(a);
	a.click();
	a.remove();
}

function pdfGenerateDynamic(setLoading){
	setLoading(true);
	fetch(process.env.REACT_APP_API + '/pdf', {headers: {Accept: "application/pdf"}})
		.then(response => response.blob())
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = "pricelist.pdf";
			a.target="_blank"
			document.body.appendChild(a); // need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove();
		})
		.then(() => {
			setLoading(false);
		})
		.catch(() => {
			console.error('problem downloading pdf price list');
			setLoading(false);
		})
}