import { Link } from "react-router-dom";

export function Footer() {
	const year = new Date().getFullYear();

    return(
		<footer className='site-footer'>
			<span className="line">&copy; {process.env.REACT_APP_COMPANIES_HOUSE_NAME} {year} </span>
			<span role="separator" aria-hidden="true"></span>
			<span className="line">Registered in the UK, company number: 03022043</span>
			<span role="separator" aria-hidden="true"></span>
			<span className="line">
				<Link to="/privacy-policy" className="privacy-policy-link">Privacy Policy</Link>
			</span>
			<span role="separator" aria-hidden="true"></span>
			<span className="line">
				<Link to="/terms-conditions" className="terms-conditions-link">Terms &amp; Conditions</Link>
			</span>
			<span role="separator" aria-hidden="true"></span>
			<span className="line">
				<Link to="/contact" className="contact-link">Contact</Link>
			</span>
			<span role="separator" aria-hidden="true"></span>
			<span className="line">
				<Link to="/help" className="help-link">Help</Link>
			</span>
			<span role="separator" aria-hidden="true"></span>
			<span className="line">Web app developed by <a href="https://www.shinygrey.com" target="_blank" rel="noopener noreferrer">Shiny Grey Ltd</a></span>
		</footer>
    );
}
