import { calculateAndFormatPriceForCsv } from './money';

export function createCsv(products,discountData,customerPriceData) {
	const sortedProducts = [...products].sort((a,b) => {
		if (a.item_group !== b.item_group) {
			return a.item_group - b.item_group;
		}

		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();

		if(nameA < nameB){
			return -1;
		}

		if(nameA > nameB){
			return 1;
		}

		return 0;
	});

	const data = sortedProducts.reduce((acc,item) => {
		const code = item.product_code;

		const priceFormatted = customerPriceData && (code in customerPriceData)
			? customerPriceData[code].price
			: calculateAndFormatPriceForCsv(item.price,item.item_group,discountData);

		return acc += code + ',' + item.name + ',' + item.item_group + ',' + + priceFormatted + ',' + item.info.unit + '\r\n';
	},`R-Code,Description,Group,Cost,Unit\r\n`);
	return [data,'text/csv','csv'];
}


export function downloadPriceListDataOnClick(dataFormatter,products,discountData,customerPriceData,optionalName,userEmail) {
	const date = new Date();
	const [fileData,mimeType,fileExtension] = dataFormatter(products,discountData,customerPriceData,userEmail,date);
	const file = new Blob([fileData], {type: mimeType});
	const a = document.createElement('a');

	const fileObject = URL.createObjectURL(file);

	a.href = fileObject;
	a.download = `pennyuk-pricelist-${date.getFullYear()}${getMonthLeadingZero(date)}${date.getDate()}${!optionalName ? '' : '-'+optionalName}.${fileExtension}`;
	a.target="_blank";
	a.setAttribute('rel', 'noopener noreferrer');
	document.body.appendChild(a);
	a.click();
	a.remove();
	URL.revokeObjectURL(fileObject);
}

function getMonthLeadingZero(date) {
	const m = date.getMonth() + 1;
	if(m < 10){
		return '0' + m;
	}else{
		return m.toString();
	}
}
