import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { selectAllProducts } from '../../redux/productsSlice';
import { createCsv, downloadPriceListDataOnClick } from '../../business/spreadsheet';

export function DownloadCsv() {
	const loggedIn = useSelector(state => state.user.loggedin);
	const discountData = useSelector(state => state.user.discounts);
	const customerPriceData = useSelector(state => state.user.products);

	if(!loggedIn){
		return(<Fragment />);
	}

	return(<Fragment>
		<p>Click below to download a .csv with your discounts applied:</p>
		<DownloadCsvButton
			discountData={discountData}
			customerPriceData={customerPriceData}
		/>
	</Fragment>);
}

export function DownloadCsvButton({ discountData, customerPriceData, name }) {
	const products = useSelector(selectAllProducts);

	return (
		<button
			type="button"
			name="download-pricelist-csv"
			className="download-pricelist lightblue-rounded-button"
			disabled={!discountData}
			onClick={() => {
				downloadPriceListDataOnClick(createCsv, products,discountData,customerPriceData, name);
			}}
		>
			<span>Download Spreadsheet</span>
		</button>
	);
}
